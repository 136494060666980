<template>
  <div class="game-container">
    <MirrorGame
      :mirrorImgList="mirrorImgList"
      :optionList="optionList"
      :isAllSecreen="true"
      :lessonNo="44"
    />
  </div>
</template>

<script>
import MirrorGame from "@/components/Course/GamePage/MirrorGame";
export default {
  data() {
    return {
      optionList: [
        {
          pinyin: "chī",
          hanzi: "吃",
          id: 0,
        },
        {
          pinyin: "hē",
          hanzi: "喝",
          id: 1,
        },
      ],
      mirrorImgList: [
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-44/image-1-chi.svg"),
          id: 0,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-44/image-2-he.svg"),
          id: 1,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-44/image-3-he.svg"),
          id: 1,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-44/image-4-chi.svg"),
          id: 0,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-44/image-5-he.svg"),
          id: 1,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-44/image-6-chi.svg"),
          id: 0,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-44/image-7-he.svg"),
          id: 1,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-44/image-8-chi.svg"),
          id: 0,
        },
      ],
    };
  },
  components: {
    MirrorGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











