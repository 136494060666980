<template>
  <div class="game-container layout-border">
    <FindStroke
      :bgImg="bgImg"
      :gameIndex="gameIndex"
      :strokeImgList="strokeImgList"
      :strockNotFoundImage="strockNotFoundImage"
      :strockFoundImage="strockFoundImage"
    />
  </div>
</template>

<script>
import FindStroke from "@/components/Course/GamePage/FindStrokeGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-44/background-flamingo.svg"),
      gameIndex:44,
      strokeImgList: [
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-44/stroke-house.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-44/stroke-house-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-44/stroke-reflection.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-44/stroke-reflection-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-44/stroke-umbrella.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-44/stroke-umbrella-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-44/stroke-waterfall.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-44/stroke-waterfall-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-44/stroke-flamingo.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-44/stroke-flamingo-found.svg"),
        },
      ],
      strockNotFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-44/stroke-shugou-pending.svg"),
      strockFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-44/stroke-shugou-found.svg"),
    };
  },
  components: {
    FindStroke,
  },
};
</script>

<style lang="scss" scoped>
</style>
